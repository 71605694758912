import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Chip,
  Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { t } from 'i18next';

export default function SpecificConfiguration({
  categories,
  includedTags,
  excludedTags,
  handleTagChange,
  showExternalExpense,
  setShowExternalExpense,
  showWorkforce,
  setShowWorkforce,
  searchTerm,
  setSearchTerm,
  budgetData,
}) {
  const [expanded, setExpanded] = useState(true);

  const availableForInclude = categories.filter(
    (category) => !excludedTags.includes(category) && !includedTags.includes(category)
  );
  const availableForExclude = categories.filter(
    (category) => !includedTags.includes(category) && !excludedTags.includes(category)
  );

  const searchOptions = budgetData.map((item) => item.identifier);

  return (
    <Accordion elevation={0} square expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h8" fontWeight="bold">
          Configuration Spécifique
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              freeSolo
              options={searchOptions}
              value={searchTerm}
              onInputChange={(event, newInputValue) => setSearchTerm(newInputValue)}
              renderInput={(params) => (
                <TextField {...params} label={t('budgetwizard.specificConfiguration.search', 'Search')} fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              options={availableForInclude}
              value={includedTags}
              onChange={(event, values) => handleTagChange('include', event, values)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip key={index} label={option} {...getTagProps({ index })} />)
              }
              renderInput={(params) => <TextField {...params} label="Inclure Catégories" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              multiple
              options={availableForExclude}
              value={excludedTags}
              onChange={(event, values) => handleTagChange('exclude', event, values)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => <Chip key={index} label={option} {...getTagProps({ index })} />)
              }
              renderInput={(params) => <TextField {...params} label="Exclure Catégories" />}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox checked={showExternalExpense} onChange={(e) => setShowExternalExpense(e.target.checked)} />
              }
              label={t('budgetwizard.specificConfiguration.externalExpense', 'External Expense')}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={<Checkbox checked={showWorkforce} onChange={(e) => setShowWorkforce(e.target.checked)} />}
              label={t('budgetwizard.specificConfiguration.workforce', 'Workforce')}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

SpecificConfiguration.propTypes = {
  categories: PropTypes.array.isRequired,
  includedTags: PropTypes.array.isRequired,
  excludedTags: PropTypes.array.isRequired,
  handleTagChange: PropTypes.func.isRequired,
  showExternalExpense: PropTypes.bool.isRequired,
  setShowExternalExpense: PropTypes.func.isRequired,
  showWorkforce: PropTypes.bool.isRequired,
  setShowWorkforce: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired,
  setSearchTerm: PropTypes.func.isRequired,
  budgetData: PropTypes.array.isRequired,
};
