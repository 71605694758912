import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import BudgetWizard from './BudgetWizard';
import budgetData from './BudgetWizard.mock.json';
import Trajectories from './Trajectories/Trajectories';

function BudgetWizardContainer() {
  const [openTrajectories, setOpenTrajectories] = useState(false);
  const [selectedBudgetItem, setSelectedBudgetItem] = useState(null);
  const [budgetDataState, setBudgetDataState] = useState(budgetData);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleConfirm = (data) => {
    console.log('Confirmed data:', data);
    setIsDialogOpen(false);
  };

  const handleEditTrajectories = (item) => {
    setSelectedBudgetItem(item);
    setOpenTrajectories(true);
  };

  const handleUpdateTrajectories = (updatedTrajectories) => {
    setBudgetDataState((prevState) =>
      prevState.map((item) =>
        item.identifier === selectedBudgetItem.identifier ? { ...item, trajectories: updatedTrajectories } : item
      )
    );
  };

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button variant="contained" onClick={handleOpenDialog}>
        Gérer les budgets
      </Button>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog} fullWidth maxWidth="lg">
        <DialogTitle>Modifier les Budgets</DialogTitle>
        <DialogContent>
          <BudgetWizard
            open={true}
            closeDialog={handleCloseDialog}
            onConfirm={handleConfirm}
            categories={['Category1', 'Category2', 'Category3', 'Category4', 'Category5']}
            budgetData={budgetDataState}
            onEditTrajectories={handleEditTrajectories}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button variant="contained" onClick={handleConfirm}>
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>

      {openTrajectories && (
        <Trajectories
          open={openTrajectories}
          closeDialog={() => setOpenTrajectories(false)}
          budgetItem={selectedBudgetItem}
          onUpdateTrajectories={handleUpdateTrajectories}
        />
      )}
    </>
  );
}

export default BudgetWizardContainer;
