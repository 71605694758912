import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useSortable } from '@dnd-kit/sortable';
import { Edit, Delete, DragIndicator, BarChart, Save, Cancel } from '@mui/icons-material';
import { TableRow, TableCell, Checkbox, TextField, Chip, Select, MenuItem, IconButton } from '@mui/material';
import CategoryManager from './CategoryManager';

export default function SortableItem({
  item,
  isEditing,
  setEditingRowId,
  onEditTrajectories,
  categories,
  onDelete,
  animateLayoutChanges,
}) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item.identifier,
    animateLayoutChanges,
  });

  const style = {
    transition: isDragging ? transition : 'none',
    transform: transform ? `translate3d(0, ${transform.y}px, 0)` : undefined,
    zIndex: isDragging ? 1000 : 'auto',
    backgroundColor: isDragging ? '#f0f0f0' : 'inherit',
  };

  const [localItem, setLocalItem] = useState({
    ...item,
    type: item.type || 'Workforce',
  });

  useEffect(() => {
    if (isEditing) {
      setLocalItem(item);
    }
  }, [isEditing, item]);

  const handleSave = () => {
    Object.assign(item, localItem);
    setEditingRowId(null);
  };

  const handleCancel = () => {
    if (item.identifier.startsWith('')) {
      onDelete(item.identifier); // Delete the new row
    } else {
      setLocalItem(item); // Revert changes for existing row
    }
    setEditingRowId(null);
  };

  const handleChange = (field, value) => {
    setLocalItem((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <TableRow ref={setNodeRef} style={style} {...attributes}>
      <TableCell {...listeners} style={{ cursor: 'grab' }}>
        <DragIndicator />
      </TableCell>
      <TableCell>
        {isEditing ? (
          <TextField
            value={localItem.identifier}
            onChange={(e) => handleChange('identifier', e.target.value)}
            fullWidth
            autoFocus
          />
        ) : (
          item.identifier
        )}
      </TableCell>
      <TableCell>
        <Checkbox
          checked={localItem.constraint}
          onChange={(e) => handleChange('constraint', e.target.checked)}
          disabled={!isEditing}
        />
      </TableCell>
      <TableCell>
        {isEditing ? (
          <Controller
            name={`capexRatio-${item.identifier}`}
            defaultValue={item.capexRatio}
            render={({ field }) => (
              <TextField
                {...field}
                value={localItem.capexRatio}
                onChange={(e) => handleChange('capexRatio', parseFloat(e.target.value))}
                type="number"
                inputProps={{ min: 0, max: 1, step: 0.01 }}
                fullWidth
                disabled={!isEditing}
              />
            )}
          />
        ) : (
          localItem.capexRatio.toFixed(2)
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <CategoryManager
            categories={categories}
            value={localItem.budgetCategories}
            onChange={(newCategories) => handleChange('budgetCategories', newCategories)}
            label="Catégories"
            placeholder="Ajouter une catégorie"
          />
        ) : (
          localItem.budgetCategories.map((category, index) => (
            <Chip key={index} label={category} style={{ margin: '2px' }} />
          ))
        )}
      </TableCell>
      <TableCell>
        {isEditing ? (
          <Select value={localItem.type} onChange={(e) => handleChange('type', e.target.value)} fullWidth>
            <MenuItem value="Workforce">Main d’oeuvre</MenuItem>
            <MenuItem value="Suppliers">Dépense Externe</MenuItem>
          </Select>
        ) : (
          item.type
        )}
      </TableCell>
      <TableCell>{item.priorityRank}</TableCell>
      <TableCell>
        <IconButton onClick={() => onEditTrajectories(item)}>
          <BarChart />
        </IconButton>
      </TableCell>
      <TableCell>
        {isEditing ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton variant="outlined" onClick={handleSave} disabled={!localItem.identifier.trim()}>
              <Save />
            </IconButton>
            <IconButton onClick={handleCancel}>
              <Cancel />
            </IconButton>
          </div>
        ) : (
          <>
            <IconButton onClick={() => setEditingRowId(item.priorityRank)}>
              <Edit />
            </IconButton>
            <IconButton onClick={() => onDelete(item.identifier)}>
              <Delete />
            </IconButton>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

SortableItem.propTypes = {
  item: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    constraint: PropTypes.bool.isRequired,
    capexRatio: PropTypes.number.isRequired,
    budgetCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
    type: PropTypes.string.isRequired,
    priorityRank: PropTypes.number.isRequired,
  }).isRequired,
  isEditing: PropTypes.bool.isRequired,
  setEditingRowId: PropTypes.func.isRequired,
  onEditTrajectories: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  animateLayoutChanges: PropTypes.func,
};
