import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionSummary, AccordionDetails, Grid, TextField, Typography } from '@mui/material';

export default function GeneralConfiguration() {
  const [expanded, setExpanded] = useState(true);

  return (
    <Accordion elevation={0} square expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h8" fontWeight="bold">
          Configuration Générale
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={6}>
            <Controller
              name="budgetStartPeriod"
              defaultValue=""
              rules={{ required: true, min: 1 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Début Période Budgétaire"
                  fullWidth
                  inputProps={{ min: 1 }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="budgetCarryOverPeriod"
              defaultValue=""
              rules={{ required: true, min: 1 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label="Période Report Budgétaire"
                  fullWidth
                  inputProps={{ min: 1 }}
                />
              )}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
