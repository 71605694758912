import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DndContext, closestCenter, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { t } from 'i18next';
import './BudgetTable.css';
import SortableItem from './SortableItem';

export default function BudgetTable({
  budgetDataState,
  handleDragEnd,
  editingRowId,
  setEditingRowId,
  onEditTrajectories,
  categories,
  onDeleteItem,
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
      axis: 'y',
    })
  );

  // Désactiver l'animation de réagencement
  const animateLayoutChanges = () => false;

  useEffect(() => {
    // Force re-render when budgetDataState changes
  }, [budgetDataState]);

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
      <SortableContext items={budgetDataState.map((item) => item.identifier)} strategy={verticalListSortingStrategy}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t('budgetwizard.table.identifier', 'Identifier')}</TableCell>
                <TableCell>{t('budgetwizard.table.constraint', 'Constraint')}</TableCell>
                <TableCell style={{ whiteSpace: 'nowrap' }}>
                  {t('budgetwizard.table.capexRatio', 'CAPEX Ratio')}
                </TableCell>
                <TableCell>{t('budgetwizard.table.budgetCategories', 'Budget Categories')}</TableCell>
                <TableCell>{t('budgetwizard.table.type', 'Type')}</TableCell>
                <TableCell>{t('budgetwizard.table.priorityRank', 'Priority Rank')}</TableCell>
                <TableCell>{t('budgetwizard.table.trajectories', 'Trajectories')}</TableCell>
                <TableCell>{t('budgetwizard.table.actions', 'Actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {budgetDataState.map((item) => (
                <SortableItem
                  key={item.identifier}
                  item={item}
                  isEditing={editingRowId === item.priorityRank}
                  setEditingRowId={setEditingRowId}
                  onEditTrajectories={() => onEditTrajectories(item)}
                  categories={categories}
                  onDelete={onDeleteItem}
                  // Passer animateLayoutChanges dans SortableItem pour qu'il soit appliqué
                  animateLayoutChanges={animateLayoutChanges}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </SortableContext>
    </DndContext>
  );
}

BudgetTable.propTypes = {
  budgetDataState: PropTypes.array.isRequired,
  handleDragEnd: PropTypes.func.isRequired,
  editingRowId: PropTypes.string,
  setEditingRowId: PropTypes.func,
  onEditTrajectories: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
};
