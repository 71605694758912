import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Chip, TextField } from '@mui/material';

/**
 * CategoryManager
 * @param {string[]} categories - Liste des catégories possibles (mock ou issues de l’API).
 * @param {string[]} value - Liste des catégories actuellement sélectionnées.
 * @param {function} onChange - Callback appelé lorsqu’on modifie la sélection.
 * Reçoit la nouvelle liste de catégories sélectionnées.
 * @param {string} label - Label affiché sur le TextField.
 * @param {string} placeholder - Placeholder affiché dans le TextField.
 */
export default function CategoryManager({ categories, value, onChange, label, placeholder }) {
  // On stocke localement toutes les catégories connues (initialisées par `categories`).
  // Le composant propose de créer des catégories inexistantes, qu’on vient ajouter ici.
  const [allCategories, setAllCategories] = useState(categories);

  // Synchronise les catégories si `categories` change en props
  useEffect(() => {
    setAllCategories(categories);
  }, [categories]);

  /**
   * Gestion de la sélection dans l’Autocomplete
   * @param {object} event - l’événement
   * @param {string[]} newValue - la nouvelle liste de valeurs sélectionnées
   */
  const handleSelectionChange = (event, newValue) => {
    // Vérifier si l’utilisateur vient de sélectionner la case "Créer XXX"
    // Elle a normalement la forme "Créer <nom>" => on parse
    const createPrefix = 'Créer ';
    const maybeCreatedItem = newValue.find((val) => val.startsWith(createPrefix));

    if (maybeCreatedItem) {
      const newCategoryName = maybeCreatedItem.replace(createPrefix, '').trim();

      // Ajout de la nouvelle catégorie dans la liste "allCategories"
      const updatedCategories = [...allCategories, newCategoryName];
      setAllCategories(updatedCategories);

      // On remplace l’item "Créer XXX" par "XXX" dans la sélection
      const replacedValue = newValue.map((val) => (val === maybeCreatedItem ? newCategoryName : val));

      // On notifie le parent via `onChange`
      onChange(replacedValue);
    } else {
      // Pas de création, on envoie simplement la nouvelle sélection au parent
      onChange(newValue);
    }
  };

  /**
   * Filtrer les options pour l’Autocomplete.
   * On injecte dynamiquement une option "Créer <nom>" lorsque l’utilisateur tape quelque chose
   * qui n’existe pas déjà dans la liste.
   */
  const filterOptions = (options, state) => {
    const filtered = options.filter((option) => option.toLowerCase().includes(state.inputValue.toLowerCase()));

    // Si l’utilisateur tape un nouveau mot qui n’existe pas, on ajoute l’option "Créer xxxxx"
    if (
      state.inputValue.trim() !== '' &&
      !options.some((opt) => opt.toLowerCase() === state.inputValue.toLowerCase())
    ) {
      filtered.push(`Créer ${state.inputValue}`);
    }

    return filtered;
  };

  return (
    <Autocomplete
      multiple
      filterOptions={filterOptions}
      options={allCategories}
      value={value}
      onChange={handleSelectionChange}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip key={option} label={option} {...getTagProps({ index })} style={{ margin: '2px' }} />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label || 'Catégories'}
          placeholder={placeholder || 'Ajouter une catégorie'}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
}

CategoryManager.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};
