import React, { useState, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid } from '@mui/material';
import BudgetTable from './BudgetTable';
import GeneralConfiguration from './GeneralConfiguration';
import SpecificConfiguration from './SpecificConfiguration';

function applyFilters(data, { showExternalExpense, showWorkforce, searchTerm, includedTags, excludedTags }) {
  return data.filter((item) => {
    if (item.type === 'Suppliers' && !showExternalExpense) return false;
    if (item.type === 'Workforce' && !showWorkforce) return false;
    if (searchTerm && !item.identifier.toLowerCase().includes(searchTerm.toLowerCase())) return false;
    if (includedTags.length > 0 && !includedTags.every((tag) => item.budgetCategories.includes(tag))) return false;
    if (excludedTags.some((tag) => item.budgetCategories.includes(tag))) return false;
    return true;
  });
}

export default function BudgetWizard({
  open,
  closeDialog,
  onConfirm,
  categories = [],
  budgetData = [],
  onEditTrajectories,
}) {
  const methods = useForm({ mode: 'onChange' });
  const { formState } = methods;

  const [masterBudgetData, setMasterBudgetData] = useState(budgetData);
  const [filteredBudgetData, setFilteredBudgetData] = useState(budgetData);

  const [includedTags, setIncludedTags] = useState([]);
  const [excludedTags, setExcludedTags] = useState([]);
  const [editingRowId, setEditingRowId] = useState(null);
  const [showExternalExpense, setShowExternalExpense] = useState(true);
  const [showWorkforce, setShowWorkforce] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');

  const initialOrderRef = useRef(budgetData.map((item) => item.identifier));

  useEffect(() => {
    setMasterBudgetData(budgetData);
    setFilteredBudgetData(budgetData);
    initialOrderRef.current = budgetData.map((item) => item.identifier);
  }, [budgetData]);

  useEffect(() => {
    const filteredData = applyFilters(masterBudgetData, {
      showExternalExpense,
      showWorkforce,
      searchTerm,
      includedTags,
      excludedTags,
    });
    setFilteredBudgetData(filteredData);
  }, [masterBudgetData, showExternalExpense, showWorkforce, searchTerm, includedTags, excludedTags]);

  const addNewRow = () => {
    const newRow = {
      identifier: '',
      constraint: true,
      capexRatio: 0,
      budgetCategories: [],
      type: 'Workforce',
      priorityRank: masterBudgetData.length + 1,
    };

    const newMasterData = [...masterBudgetData, newRow];
    newMasterData.forEach((item, idx) => {
      item.priorityRank = idx + 1;
    });

    setMasterBudgetData(newMasterData);
    setEditingRowId(newRow.priorityRank);

    initialOrderRef.current = [...initialOrderRef.current, newRow.identifier];
  };

  const resetOrder = () => {
    const orderMap = new Map();
    initialOrderRef.current.forEach((id, index) => {
      orderMap.set(id, index);
    });

    const newMasterData = [...masterBudgetData].sort((a, b) => {
      const indexA = orderMap.has(a.identifier) ? orderMap.get(a.identifier) : Infinity;
      const indexB = orderMap.has(b.identifier) ? orderMap.get(b.identifier) : Infinity;
      return indexA - indexB;
    });

    newMasterData.forEach((item, idx) => {
      item.priorityRank = idx + 1;
    });

    setMasterBudgetData(newMasterData);
  };

  const confirm = () => {
    console.log('Budget Data (Confirm):', masterBudgetData);
    onConfirm(masterBudgetData);
  };

  const handleTagChange = (type, event, values) => {
    if (type === 'include') {
      setIncludedTags(values);
      setExcludedTags(excludedTags.filter((tag) => !values.includes(tag)));
    } else {
      setExcludedTags(values);
      setIncludedTags(includedTags.filter((tag) => !values.includes(tag)));
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const activeId = active.id;
    const overId = over.id;

    const oldIndexInMaster = masterBudgetData.findIndex((item) => item.identifier === activeId);
    const newIndexInMaster = masterBudgetData.findIndex((item) => item.identifier === overId);

    if (oldIndexInMaster === -1 || newIndexInMaster === -1) return;

    const newMasterData = [...masterBudgetData];
    const [movedItem] = newMasterData.splice(oldIndexInMaster, 1);

    newMasterData.splice(newIndexInMaster, 0, movedItem);

    newMasterData.forEach((item, idx) => {
      item.priorityRank = idx + 1;
    });

    setMasterBudgetData(newMasterData);
  };

  const updateBudgetDataState = (newFilteredState) => {
    setFilteredBudgetData(newFilteredState);

    const newMasterData = [...masterBudgetData];
    newFilteredState.forEach((updatedItem) => {
      const idx = newMasterData.findIndex((it) => it.priorityRank === updatedItem.priorityRank);
      if (idx !== -1) {
        newMasterData[idx] = { ...updatedItem };
      } else {
        newMasterData.push(updatedItem);
      }
    });

    newMasterData.sort((a, b) => a.priorityRank - b.priorityRank);
    setMasterBudgetData(newMasterData);
  };

  const onDeleteItem = (identifier) => {
    const newMasterData = masterBudgetData.filter((item) => item.identifier !== identifier);
    newMasterData.forEach((item, idx) => {
      item.priorityRank = idx + 1;
    });
    setMasterBudgetData(newMasterData);
  };

  return (
    <FormProvider {...methods}>
      <Dialog open={open} fullWidth maxWidth="lg">
        <DialogTitle>Budget Wizard</DialogTitle>
        <DialogContent>
          <Grid container gap={1} flexDirection="column">
            <GeneralConfiguration />
            <SpecificConfiguration
              categories={categories}
              includedTags={includedTags}
              excludedTags={excludedTags}
              handleTagChange={handleTagChange}
              showExternalExpense={showExternalExpense}
              setShowExternalExpense={setShowExternalExpense}
              showWorkforce={showWorkforce}
              setShowWorkforce={setShowWorkforce}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              budgetData={masterBudgetData}
            />
            <BudgetTable
              budgetDataState={filteredBudgetData}
              handleDragEnd={handleDragEnd}
              updateBudgetDataState={updateBudgetDataState}
              editingRowId={editingRowId}
              setEditingRowId={setEditingRowId}
              onEditTrajectories={onEditTrajectories}
              categories={categories}
              onDeleteItem={onDeleteItem}
            />
            <Grid container justifyContent="space-between">
              <Button variant="outlined" onClick={resetOrder}>
                Réinitialiser l&apos;ordre
              </Button>
              <Button variant="outlined" onClick={addNewRow}>
                Ajouter une ligne
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Annuler</Button>
          <Button variant="contained" disabled={formState.isValid} onClick={confirm}>
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}

BudgetWizard.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  categories: PropTypes.array,
  budgetData: PropTypes.array,
  onEditTrajectories: PropTypes.func.isRequired,
};
