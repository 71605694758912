import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Delete, Edit, Upload, Save, Cancel } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Grid,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

/**
 * Recalcule les périodes pour éviter les trous, fusionner ou découper si besoin.
 */
const recalculatePeriods = (data) => {
  if (!data || data.length === 0) return [];

  const sorted = [...data].sort((a, b) => a.startYear - b.startYear);
  const result = [];

  for (let i = 0; i < sorted.length; i++) {
    const current = { ...sorted[i] };
    if (result.length === 0) {
      result.push(current);
      continue;
    }

    const last = result[result.length - 1];

    // Si trou entre la dernière fin et le nouveau début
    if (current.startYear > last.endYear + 1) {
      last.endYear = current.startYear - 1;
      result.push(current);
    } else {
      // Fusion ou découpage si recouvrement
      if (current.quantiteDisponible === last.quantiteDisponible) {
        last.endYear = Math.max(last.endYear, current.endYear);
      } else {
        if (current.startYear <= last.endYear) {
          if (current.startYear > last.startYear) {
            last.endYear = current.startYear - 1;
          }
          result.push(current);
        } else {
          result.push(current);
        }
      }
    }
  }

  return result;
};

/**
 * Convertit les périodes en un tableau "année par année" pour l'affichage du BarChart.
 */
const fillMissingYearsForChart = (data) => {
  if (data.length === 0) return [];
  const filledData = [];

  data.forEach((item) => {
    for (let year = item.startYear; year <= item.endYear; year++) {
      filledData.push({
        annee: year,
        quantiteDisponible: item.quantiteDisponible,
      });
    }
  });

  return filledData;
};

export default function Trajectories({ open, closeDialog, budgetItem, onUpdateTrajectories }) {
  const { t } = useTranslation();

  const [data, setData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [editingRowData, setEditingRowData] = useState({
    startYear: '',
    endYear: '',
    quantiteDisponible: '',
  });
  const [initialData, setInitialData] = useState([]);

  /**
   * Récupération de la configuration initiale à l’ouverture du Dialog
   */
  useEffect(() => {
    if (budgetItem) {
      const initialTrajectories = budgetItem.trajectories || [];
      const sortedData = [...initialTrajectories].sort((a, b) => a.startYear - b.startYear);
      setData(sortedData);
      setChartData(sortedData);
      setIsEditing(null);
      setInitialData(sortedData);
    }
  }, [budgetItem]);

  /**
   * Lecture CSV (non implémentée), met à jour la data.
   */
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // parse CSV, etc.
      const parsedData = []; // À remplir
      const updatedData = [...data, ...parsedData];
      const recalculated = recalculatePeriods(updatedData);

      setData(recalculated);
      setChartData(recalculated);
      onUpdateTrajectories && onUpdateTrajectories(recalculated);
      setIsEditing(null);
    }
  };

  /**
   * Ajout d’une nouvelle ligne (par défaut, la période commence après la dernière)
   */
  const handleAddRow = () => {
    const lastPeriod = data[data.length - 1];
    const defaultStartYear = lastPeriod ? lastPeriod.endYear + 1 : new Date().getFullYear();
    const defaultEndYear = defaultStartYear + 10;

    setEditingRowData({
      startYear: defaultStartYear,
      endYear: defaultEndYear,
      quantiteDisponible: 0,
    });
    setIsEditing(data.length);
  };

  /**
   * Validation de la ligne éditée
   */
  const isRowValid = (rowData) => {
    const { startYear, endYear, quantiteDisponible } = rowData;
    return (
      startYear &&
      endYear &&
      !isNaN(startYear) &&
      !isNaN(endYear) &&
      !isNaN(quantiteDisponible) &&
      parseInt(startYear, 10) <= parseInt(endYear, 10)
    );
  };

  /**
   * Sauvegarde de la ligne éditée ou ajoutée
   */
  const handleSaveRow = (index) => {
    if (!isRowValid(editingRowData)) {
      alert(t('trajectories.invalidRow', 'Please fill in all fields correctly.'));
      return;
    }
    setData((prev) => {
      const newData = [...prev];
      if (index >= newData.length) {
        newData.push(editingRowData);
      } else {
        newData[index] = {
          ...newData[index],
          startYear: Number(editingRowData.startYear),
          endYear: Number(editingRowData.endYear),
          quantiteDisponible: Number(editingRowData.quantiteDisponible),
        };
      }
      const recalculated = recalculatePeriods(newData);
      setChartData(recalculated);
      onUpdateTrajectories && onUpdateTrajectories(recalculated);
      setIsEditing(null);
      return recalculated;
    });
  };

  /**
   * Suppression d’une ligne puis recalcul
   */
  const handleRemoveRow = (index) => {
    setData((prev) => {
      const newData = prev.filter((_, i) => i !== index);
      const recalculated = recalculatePeriods(newData);
      setChartData(recalculated);
      onUpdateTrajectories && onUpdateTrajectories(recalculated);
      return recalculated;
    });
  };

  /**
   * Gestion du formulaire d’édition
   */
  const handleChange = (field, value) => {
    setEditingRowData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEdit = (index) => {
    setIsEditing(index);
    const row = data[index];
    setEditingRowData({
      startYear: row.startYear,
      endYear: row.endYear,
      quantiteDisponible: row.quantiteDisponible ?? '',
    });
  };

  const handleCancel = () => {
    setIsEditing(null);
    setEditingRowData({
      startYear: '',
      endYear: '',
      quantiteDisponible: '',
    });
  };

  /**
   * Remet l’état du tableau tel qu’au départ
   */
  const handleReset = () => {
    setData(initialData);
    setChartData(initialData);
    setIsEditing(null);
    setEditingRowData({
      startYear: '',
      endYear: '',
      quantiteDisponible: '',
    });
  };

  const isRowBeingEdited = (index) => isEditing === index;

  /**
   * Données annuelles pour le graphique
   */
  const filledDataForDisplay = fillMissingYearsForChart(chartData);

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>Trajectoires</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {/* Informations sur le budgetItem */}
          <Grid container spacing={2} mt={2} justifyContent="space-between">
            <Grid item xs={4}>
              <TextField
                label={t('trajectories.budgetEnvelope', 'Budget Envelope')}
                value={budgetItem.identifier}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={4}>
              <TextField label={t('trajectories.type', 'Type')} value={budgetItem.type} fullWidth disabled />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={t('trajectories.budgetCategories', 'Budget Categories')}
                value={budgetItem.budgetCategories.join(', ')}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>

          {/* Bouton de chargement CSV */}
          <Grid item xs={12}>
            <Button variant="outlined" component="label">
              {t('trajectories.loadCsv', 'Load Trajectories')} <Upload />
              <input type="file" hidden onChange={handleFileUpload} />
            </Button>
          </Grid>

          {/* Tableau des périodes */}
          <Grid item xs={12}>
            <TableContainer component={Paper} sx={{ maxHeight: 300 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('trajectories.period', 'Period')}</TableCell>
                    <TableCell>{t('trajectories.availableQuantity', 'Available Quantity')}</TableCell>
                    <TableCell>{t('trajectories.actions', 'Actions')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {isRowBeingEdited(index) ? (
                          <Grid container spacing={1}>
                            <Grid item xs={6}>
                              <TextField
                                label={t('trajectories.startYear', 'Start Year')}
                                value={editingRowData.startYear}
                                onChange={(e) => handleChange('startYear', e.target.value)}
                                type="number"
                                inputProps={{ min: 1900, max: 2100 }}
                                fullWidth
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                label={t('trajectories.endYear', 'End Year')}
                                value={editingRowData.endYear}
                                onChange={(e) => handleChange('endYear', e.target.value)}
                                type="number"
                                inputProps={{ min: 1900, max: 2100 }}
                                fullWidth
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          `${row.startYear} - ${row.endYear}`
                        )}
                      </TableCell>

                      <TableCell>
                        {isRowBeingEdited(index) ? (
                          <TextField
                            value={editingRowData.quantiteDisponible}
                            type="number"
                            fullWidth
                            onChange={(e) => handleChange('quantiteDisponible', e.target.value)}
                          />
                        ) : (
                          row.quantiteDisponible
                        )}
                      </TableCell>

                      <TableCell>
                        {isRowBeingEdited(index) ? (
                          <>
                            <IconButton onClick={() => handleSaveRow(index)} disabled={!isRowValid(editingRowData)}>
                              <Save />
                            </IconButton>
                            <IconButton onClick={handleCancel}>
                              <Cancel />
                            </IconButton>
                          </>
                        ) : (
                          <>
                            <IconButton onClick={() => handleEdit(index)}>
                              <Edit />
                            </IconButton>
                            <IconButton onClick={() => handleRemoveRow(index)}>
                              <Delete />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* Ligne d’ajout (nouveau row) */}
                  {isRowBeingEdited(data.length) && (
                    <TableRow>
                      <TableCell>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <TextField
                              label={t('trajectories.startYear', 'Start Year')}
                              value={editingRowData.startYear}
                              onChange={(e) => handleChange('startYear', e.target.value)}
                              type="number"
                              inputProps={{ min: 1900, max: 2100 }}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label={t('trajectories.endYear', 'End Year')}
                              value={editingRowData.endYear}
                              onChange={(e) => handleChange('endYear', e.target.value)}
                              type="number"
                              inputProps={{ min: 1900, max: 2100 }}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        <TextField
                          value={editingRowData.quantiteDisponible}
                          type="number"
                          fullWidth
                          onChange={(e) => handleChange('quantiteDisponible', e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleSaveRow(data.length)} disabled={!isRowValid(editingRowData)}>
                          <Save />
                        </IconButton>
                        <IconButton onClick={handleCancel}>
                          <Cancel />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container justifyContent="flex-end" mt={2}>
              <Button variant="contained" onClick={handleAddRow}>
                {t('trajectories.addRow', 'Add Row')}
              </Button>
            </Grid>
          </Grid>

          {/* Graphique */}
          <Grid item xs={12}>
            {chartData.length > 0 && (
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={filledDataForDisplay}>
                  <XAxis dataKey="annee" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="quantiteDisponible" fill="#8884d8" barSize={40} />
                </BarChart>
              </ResponsiveContainer>
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleReset}>Réinitialiser</Button>
        <Button variant="contained" onClick={closeDialog}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

Trajectories.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  budgetItem: PropTypes.shape({
    identifier: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    budgetCategories: PropTypes.arrayOf(PropTypes.string).isRequired,
    trajectories: PropTypes.arrayOf(
      PropTypes.shape({
        startYear: PropTypes.number,
        endYear: PropTypes.number,
        quantiteDisponible: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }).isRequired,
  onUpdateTrajectories: PropTypes.func.isRequired,
};
